import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import MetaMaskWallet from "../../pages/user data/collection/MetaMaskWallet";
import UserPopup from "./UserPopup";
// import light from "../../assets/light.png";
// import dark from "../../assets/dark.png";
import Logo from "../logo/logo";

const HomeHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light container">
        <Link to="/">
          <Logo />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav mr-auto"></ul>
          <UserPopup />{" "}
        </div>
      </nav>
      {/* <div className="container">
        <div className="row pt-2 algin-content-center">
          <div className="xs-4 col-4 col-sm-4 col-md-4 col-lg-4 d-flex">
            <div className="logo d-flex align-items-center">
              <Link to="/">
                <Logo />
              </Link>
            </div>
          </div>
          <div className="xs-8 col-8 col-sm-8 col-md-8 col-lg-8">
            <div className="text-right">
              <span>
              <UserPopup />
              </span>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HomeHeader;
