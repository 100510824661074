import validation from "./Validation";

/**
 * configOption
 */
export function configOption() {
  const token = !validation.isEmpty(localStorage.niftMintToken)
    ? localStorage.niftMintToken
    : "";
  // console.log("token", token);
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
}

export function fileconfigOption() {
  const token = !validation.isEmpty(localStorage.niftMintToken)
    ? localStorage.niftMintToken
    : "";
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
}
