import React, { useState } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import { API_BASE_URL, API_ROUTES } from "../../../constants/ApiBaseUrl";
import { useNavigate } from "react-router-dom";
import { configOption } from "../../../utils/HttpHeaders";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const FirstForm = (props) => {
  const navigate = useNavigate();
  const [emails, setEmails] = useState("");
  const [password, setPassword] = useState("");
  const [usernames, setUserNames] = useState("");
  const [discordUser, setDiscordUser] = useState("");
  const [twitterUser, setTwitterUser] = useState("");

  // const { username } = JSON.parse(sessionStorage.getItem("niftMintUser"));

  // const { email } = JSON.parse(sessionStorage.getItem("niftMintUser"));
  const email = props.auth.user.email;
  const id = props.auth.user.id;
  const username = props.auth.user.username;

  const validateForm = (e) => {
    e.preventDefault();

    if (!email || !password || !discordUser || !twitterUser) {
      toast.error("Please fill all the fields.");
      return false;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    // const { id, email } = JSON.parse(sessionStorage.getItem("niftMintUser"));

    var uname;
    if (usernames) {
      uname = usernames;
    } else {
      uname = username;
    }
    try {
      const res = await Axios.post(
        `${API_BASE_URL}${API_ROUTES.USER.ACCOUNT_SETTING_FIRST_FORM}`,
        {
          id,
          email,
          password,
          username: uname,

          discord_username: discordUser,
          twitter_handle: twitterUser,
        },
        configOption()
      );

      if (res.status === 200) {
        toast.success(res.data.message);
        sessionStorage.setItem("niftMintUser", JSON.stringify(res.data));

        setEmails("");
        setUserNames("");
        setPassword("");

        setDiscordUser("");
        setTwitterUser("");
        window.location.href = "/user-info";
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="">
      <div className="">
        <h5 className="font-weight-bold">Change Account Information</h5>
        <h6 className="account-desc">Update User Name, Email, Discord</h6>
        <form className="mt-5" onSubmit={validateForm}>
          <div className="form-group">
            <label className=" forms-label userinfo-labels">
              Current Password*
            </label>
            <input
              type="password"
              placeholder="******"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className=" forms-label userinfo-labels">User Name*</label>
            <input
              type="text"
              className="form-control"
              placeholder="user name"
              defaultValue={username}
              onChange={(e) => setUserNames(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label className=" forms-label userinfo-labels">Email*</label>
            <input
              type="email"
              className="form-control"
              placeholder="email"
              defaultValue={email}
              onChange={(e) => setEmails(e.target.value)}
              readOnly
            />
          </div>

          <div className="d-flex">
            <div className="form-group mr-4">
              <label className=" forms-label userinfo-labels">
                Discord Username
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="@"
                value={discordUser}
                onChange={(e) => setDiscordUser(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className=" forms-label userinfo-labels">
                Twitter Handle
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="@"
                value={twitterUser}
                onChange={(e) => setTwitterUser(e.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="common-btn text-white  mt-4 first-btn "
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

// export default FirstForm;

FirstForm.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(FirstForm);
