// import { isEmpty } from "../../Util/validation";
import Validation from "../../utils/Validation";

import { ActionType } from "../actionTypes/index";

const initialState = {
  isAuthenticated: false,
  user: {},
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !Validation.isEmpty(action.payload),
        user: action.payload,
      };
    case ActionType.CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    default:
      return state;
  }
}

export default authReducer;
