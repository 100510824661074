import React, { useState } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import { API_BASE_URL, API_ROUTES } from "../../../constants/ApiBaseUrl";
import { configOption } from "../../../utils/HttpHeaders";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const ThirdForm = (props) => {
  const [phone_number, setPhone_Number] = useState("");
  const [authApp, setAuthApp] = useState("");
  const [loading, setLoading] = useState(false);

  const validateForm = (e) => {
    e.preventDefault();

    if (!phone_number) {
      toast.error("Please fill all the fields.");
      return false;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    // const { id } = JSON.parse(localStorage.niftMintUser);
    const id = props.auth.user.id;
    try {
      const res = await Axios.post(
        `${API_BASE_URL}${API_ROUTES.AUTH_USER.FORGET_PASSWORD_STEP3}/${id}`,
        {
          phone_number,
        },
        configOption()
      );

      if (res.status === 200) {
        toast.success(
          res.data.message ? res.data.message : "Submitted successfully."
        );
        setPhone_Number("");
        sessionStorage.setItem("niftMintUser", JSON.stringify(res.data));
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="auth-container">
        <div className="">
          <h5 className="font-weight-bold">Authentication Preferences</h5>
          <h6 className="account-desc ">
            {" "}
            Select your preferred authentication method
          </h6>
          <form className="mt-5" onSubmit={validateForm}>
            <div className="form-group">
              <label className=" forms-label userinfo-labels">
                Phone Number
              </label>
              <input
                type="text"
                className="form-control"
                value={phone_number}
                onChange={(e) => setPhone_Number(e.target.value)}
              />
            </div>
            <div
              className="form-group mb-5 second-msg"
              style={{ visibility: "hidden" }}
            >
              <label className=" forms-label userinfo-labels">
                Authentication App
              </label>
              <input
                type="text"
                className="form-control"
                value={authApp}
                onChange={(e) => setAuthApp(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="common-btn text-white  third-btn"
              style={{ marginTop: "10.1rem" }}
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

// export default ThirdForm;

ThirdForm.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(ThirdForm);
