import setAuthToken from "../../utils/SetAuth";
import axios from "axios";
import Validation from "../../utils/Validation";
import { ActionType } from "../actionTypes";
import { API_BASE_URL, API_ROUTES } from "../../constants/ApiBaseUrl";

export const signIn =
  ({ payload }) =>
  (dispatch) => {
    axios
      .post(API_BASE_URL.concat(API_ROUTES.AUTH_USER.LOGIN), payload)
      .then((res) => {
        if (!Validation.isEmpty(res)) {
          const user = setUserLocally(res.data);
          setToken(user);
          dispatch(setCurrentUser(user));
          dispatch(setError({}));
        }
      })
      .catch((err) => {
        if (!Validation.isEmpty(err)) {
          dispatch(setError(err.response.data));
        }
      });
  };

/**
 * Set user
 * @param {*} payload
 * @returns
 */
export const setCurrentUser = (payload) => {
  return {
    type: ActionType.SET_CURRENT_USER,
    payload: payload,
  };
};

export const setToken = (user) => {
  setAuthToken(user.auth_token);
  // console.log(`Setting auth token: ${user.auth_token}`);
  // Save to localStorage
  localStorage.setItem("niftMintToken", user.auth_token);
};

export const setUserLocally = (user) => {
  localStorage.setItem("niftMintUser", JSON.stringify(user));
  setCurrentUser(user);
  // Set token to Auth header
  return user;
};

/**
 * Set error
 * @param {*} payload
 * @returns
 */
export const setError = (payload) => {
  return {
    type: ActionType.SET_ERROR,
    payload: payload,
  };
};

export const clearCurrentProfile = () => {
  localStorage.removeItem("niftMintToken");
  localStorage.removeItem("niftMintUser");
  return {
    type: ActionType.CLEAR_CURRENT_USER,
  };
};
