import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../logo/logo";

const RegisterButtonHeader = () => {
  return (
    <>
      <div className="container">
        <div className="row pt-3">
          <div className="xs-4 col-4 col-sm-4 col-md-4 col-lg-4 d-flex">
            <div className="logo d-flex align-items-center">
              {/* <Link to="/"> */}

              <Logo />
            </div>
          </div>
          <div className="xs-8 col-8 col-sm-8 col-md-8 col-lg-8">
            <div className="text-right">
              {/* <span className="join-telegram-parent">
                {" "}
                <a
                  href="https://t.me/+KJdjiS0rT7s2NTNh"
                  target="_blank"
                  className="join-telegram"
                >
                  Join Telegram
                </a>
              </span> */}
              <Link to="/register">
                <button className="btn  my-2 my-sm-0 header-btn">
                  Register
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterButtonHeader;
