import { useState, useRef, useEffect } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import Spinner from "../../../components/spinner/Spinner";
// import ErrorMessage from "./ErrorMessage";

const signMessage = async ({ setError, message }) => {
  try {
    if (!window.ethereum)
      throw new Error("No crypto wallet found. Please install it.");

    await window.ethereum.send("eth_requestAccounts");
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    const address = await signer.getAddress();
    return {
      // message,
      // signature,
      address,
    };
  } catch (err) {
    setError(err.message);
  }
};

// const networks = {
//   polygon: {
//     chainId: `0x${Number(137).toString(16)}`,
//     chainName: "Polygon Mainnet",
//     nativeCurrency: {
//       name: "MATIC",
//       symbol: "MATIC",
//       decimals: 18,
//     },
//     rpcUrls: ["https://polygon-rpc.com/"],
//     blockExplorerUrls: ["https://polygonscan.com/"],
//   },
// harmony: {
//   chainId: `0x${Number(1666600000).toString(16)}`,
//   chainName: "Harmony Mainnet",
//   nativeCurrency: {
//     name: "ONE",
//     symbol: "ONE",
//     decimals: 18,
//   },
//   rpcUrls: ["https://api.harmony.one"],
//   blockExplorerUrls: ["https://explorer.harmony.one/"],
// },
// };

// const changeNetwork = async ({ networkName, setError }) => {
//   try {
//     if (!window.ethereum) throw new Error("No crypto wallet found");
//     await window.ethereum.request({
//       method: "wallet_addEthereumChain",
//       params: [
//         {
//           ...networks[networkName],
//         },
//       ],
//     });
//   } catch (err) {
//     setError(err.message);
//   }
// };

export default function MetaMaskWallet({
  metaIcon,
  handleBlockchain,
  handleClose,
}) {
  // const resultBox = useRef();
  const [signatures, setSignatures] = useState([]);
  const [error, setError] = useState();
  const [connButtonText, setConnButtonText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSign = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);

          //   getAccountBalance(result[0]);
        })
        .catch((error) => {
          // setErrorMessage(error.message);
        });
    } else {
      sessionStorage.removeItem("wallet address");

      let URL = "https://metamask.io/";
      window.open(URL, "_blank");
    }
  };

  const accountChangedHandler = async (newAccount) => {
    sessionStorage.removeItem("wallet address");
    window.ethereum.on("chainChanged", chainChangedHandler);

    if (window.ethereum.networkVersion == 137) {
      var pattern = /^0x[a-fA-F0-9]{40}$/;

      if (pattern.test(newAccount) == true) {
        setError();
        const sig = await signMessage({
          setError,
          message:
            "Welcome to Niftmint! Please sign in to approve Niftmint to read your public address.",
        });

        if (sig) {
          sessionStorage.setItem("wallet address", sig.address);
          handleBlockchain();
        }
      } else {
        toast.error("address is not correct");
      }
    } else {
      toast.error("wrong network,please select Polygon Mainnet");

      setConnButtonText("Connect Wallet ");
    }
  };

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    sessionStorage.removeItem("wallet address");
    window.location.reload();
  };
  var add = sessionStorage.getItem("wallet address");

  return (
    <>
      <div className="" onClick={handleSign}>
        {handleClose || metaIcon == true ? (
          <img
            src="/assets/images/metmaskimg.png"
            width={35}
            height={35}
            style={{
              cursor: "pointer",
              objectFit: "cover",
              borderRadius: "25px",
            }}
          />
        ) : connButtonText ? (
          connButtonText
        ) : add ? (
          "Wallet Connected"
        ) : (
          "Connect Wallet"
        )}
      </div>
    </>
  );
}
