export function convertDate(mongoDate) {
  var created_date = new Date(mongoDate);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var year = created_date.getFullYear();
  var month = months[created_date.getMonth()];
  //   console.log(month);
  var month2 = created_date.getMonth(); // 11

  var date = created_date.getDate();
  var hour = created_date.getHours();
  var min = created_date.getMinutes();
  var sec = created_date.getSeconds();
  var time = month + " " + date + ", " + year + " ";
  var time2 = month2 + "/" + date + "/" + year;
  return time;
}

export const TimeStampToDate = (data) => {
  var a = new Date(data);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = date + " " + month + " " + year;

  return time;
};
