import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import AppRoutes from "./routes/AppRoutes";
import "./App.css";
import { setCurrentUser, clearCurrentProfile } from "./state/actions/auth";
import { UserProvider } from "./context/context";
import { Provider } from "react-redux";
import setAuthToken from "./utils/SetAuth";
import store from "./state/store";

const App = () => {
  useEffect(() => {
    if (localStorage.niftMintToken) {
      const user = localStorage.niftMintUser;
      const token = localStorage.niftMintToken;
      checkCurrentUser({ user: user, token: token });
    }
  }, [localStorage.niftMintToken]);

  const checkCurrentUser = ({ user, token }) => {
    setAuthToken(token);
    store.dispatch(setCurrentUser(JSON.parse(user)));
  };
  return (
    <>
      <Provider store={store}>
        <UserProvider>
          <AppRoutes />
        </UserProvider>
      </Provider>
    </>
  );
};

export default App;
