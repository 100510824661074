import React from "react";
import "../../styles/header footer styles/footer.css";
import { GrLinkedin, GrTwitter, GrYoutube } from "react-icons/gr";
import Logo from "../logo/logo";
// import lightLogo from "../assets/light.png";
import lightLogo from "../../assets/light.png";
import dark from "../../assets/ligonierwhite.png";
import solas from "../../assets/solas.png";
import ligonierministries from "../../assets/LigonierMinistriesWhiteFont.png";

// import SolasLogo from ""
const Footer = () => {
  return (
    <>
      <div className=" parent">
        <div className="container">
          {/* <div className="row parent-sections ">
            <div className=" col-16 col-sm-16 col-md-4 col-lg-4 first-section "></div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 second-section">
              <div className="upper-section d-flex  justify-content-end text-white pt-4 pt-lg-0">
                <div className="common-div ">
                  <a
                    href="mailto:info@niftmint.com?&subject=Question for niftmint"
                    target="_blank"
                    className="footer-link text-white"
                  >
                    CUSTOMER SUPPORT
                  </a>{" "}
                </div>
                <div className="common-div ">
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    className="footer-link text-white"
                  >
                    PRIVACY POLICY
                  </a>{" "}
                </div>
                <div className="term">
                  {" "}
                  <a
                    href="/terms-condition"
                    target="_blank"
                    className="footer-link text-white"
                  >
                    TERMS & CONDITIONS
                  </a>{" "}
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="row">
            <div className="col-md-12 col-lg-12 mb-1">
              <div className=" text-white footer-logo ">
                <div className="img-row d-flex justify-content-end py-3">
                  <div className="footer-logos">
                    <img className="mobile-logo1" src={dark} alt="" />
                  </div>
                  <span
                    className="d-flex align-items-center"
                    style={{ fontSize: "18px" }}
                  >
                    In Partnership with
                  </span>
                  <a
                    href="https://www.niftmint.com"
                    target="_blank"
                    style={{ position: "relative", left: "7px" }}
                  >
                    <img
                      src="/assets/images/footerLogo.png"
                      alt="footer pic"
                      className="footer-img"
                    />
                  </a>

                  <img
                    src={solas}
                    className="solaslogo"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div> */}

          <div className="footerFirstRowAllignment">
            <div className="footer-logos" style={{ marginLeft: -77 }}>
              <img
                className="mobile-logo1"
                src={dark}
                alt=""
                style={{ width: "auto", height: "100px", objectFit: "contain" }} // Adjust size as needed
              />
            </div>

            <div
              style={{ display: "flex", alignItems: "center", marginTop: 23 }}
            >
              <a
                href="mailto:info@niftmint.com?&subject=Question for niftmint"
                target="_blank"
                className="footer-link text-white"
                style={{ marginRight: 15 }}
              >
                CUSTOMER SUPPORT
              </a>
              <a
                href="/privacy-policy"
                target="_blank"
                className="footer-link text-white"
                style={{ marginRight: 15 }}
              >
                PRIVACY POLICY
              </a>
              <a
                href="/terms-condition"
                target="_blank"
                className="footer-link text-white"
              >
                TERMS & CONDITIONS
              </a>
            </div>
          </div>
          <div
            className="footerFirstRowAllignment"
            style={{ justifyContent: "flex-end" }}
          >
            <img
              // className="mobile-logo1"
              src={ligonierministries}
              alt=""
              className="footer-img2"
              style={{
                objectFit: "contain",
              }}
            />
          </div>

          <div className="row footerSecondRowAllignment">
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              {/* Set the container to be a flex container too */}
              {/* <div className="footer-logos"> */}
              {/* </div> */}
              <span className="text-white" style={{ marginLeft: "10px" }}>
                Powered by
              </span>
              <a href="https://www.niftmint.com" target="_blank">
                <img
                  src="/assets/images/footerLogo.png"
                  alt="footer pic"
                  className="footer-img"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </a>
              <img
                src={solas}
                className="solaslogo"
                style={{
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
