import React, { useState, useEffect } from "react";

import Account from "../account setting/Account";
import Collection from "../collection/Collection";
import "../../../styles/Tabs/Tab.css";
import { Link } from "react-router-dom";
import CommonSection from "../common-section/CommonSection";
import BlockchainWallet from "../blockchain-wallet/BlockchainWallet";
import Axios from "axios";
import { configOption } from "../../../utils/HttpHeaders";
import {
  API_BASE_URL,
  API_ROUTES,
} from "../../../constants/ApiBaseUrl";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const ToggleTab = (props) => {
  const [allNft, setAllNft] = useState("");
  const [loading, setLoading] = useState(false);
  const [claimed, setClaimed] = useState([]);
  const [unclaimed, setUnclaimed] = useState([]);
  useEffect(() => {
    getClaimedNft();
  }, [props.auth.user]);
  // const { id, email } = JSON.parse(sessionStorage.getItem("niftMintUser"));
  const email = props.auth.user.email;

  const getClaimedNft = async () => {
    try {
      setLoading(true);
      const encodedEmail = encodeURIComponent(email);
      const res = await Axios.get(
        `${API_BASE_URL}${API_ROUTES.NFT.CLAIMED_NFT}/${email}`,
        configOption()
      );
      setTimeout(function () {
        // setLoading(false);
      }, 2000);

      const claimed = res.data.data.filter((nft) => nft.claimed === true);
      setLoading(false);
      setClaimed(claimed);
      setAllNft(res.data.data);
      setClaimed(res.data.data);
    } catch (error) {
    }
  };

  const getUnclaimedNft = async () => {
    try {
      setLoading(true);
      const res = await Axios.get(
        `${API_BASE_URL}${API_ROUTES.USER.USER_COLLECTION}/${email}?claimed=false`,
        configOption()
      );

      setTimeout(function () {
        setLoading(false);
      }, 2000);
      setUnclaimed(res.data.data);
      setAllNft(res.data.data);
    } catch (error) {
    }
  };

  return (
    <>
      <div className="container pt-5 toggle-container">
        <h1 className="font-weight-bold py-3">Your Collection</h1>
        {/* <ul className="nav nav-pills mb-3 pt-2" id="pills-tab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link tab-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Claimed
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link tab-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Unclaimed
            </a>
          </li>
        </ul> */}
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <Collection
              claimed={true}
              allNft={claimed}
              claimedAllNfts={claimed.length > 0 ? true : false}
              loading={loading}
              nfts={allNft.length}
            />
          </div>
          {/* <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <Collection
              unclaimed={false}
              claimedAllNfts={claimed.length > 0 ? true : false}
              allNft={unclaimed}
              loading={false}
              nfts={allNft.length}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

// export default ToggleTab;

ToggleTab.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(ToggleTab);
