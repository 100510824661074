import React, { useState } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import { API_BASE_URL, API_ROUTES } from "../../../constants/ApiBaseUrl";
import { configOption } from "../../../utils/HttpHeaders";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const SecondForm = (props) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const validateForm = (e) => {
    e.preventDefault();

    if (!password || !newPassword || !confirmNewPassword) {
      toast.error("Please fill all the fields.");
      return false;
    } else if (password.length < 6) {
      toast.error("Password length must be greater than 6 characters.");
      return false;
    } else if (newPassword !== confirmNewPassword) {
      toast.error("Passwords do not match.");
      return false;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    // const { id } = JSON.parse(localStorage.niftMintUser);
    const id = props.auth.user.id;
    try {
      const res = await Axios.post(
        `${API_BASE_URL}${API_ROUTES.USER.ACCOUNT_SETTING_SECOND_FORM}/${id}`,
        {
          password,
          newPassword,
        },
        configOption()
      );

      if (res.status === 200) {
        toast.success(res.data.message);

        setPassword("");
        setConfirmNewPassword("");
        setNewPassword("");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="second-container">
      <div className="">
        <h5 className="font-weight-bold">Change Password</h5>
        <h6 className="account-desc">Update User Name, Email, Discord</h6>
        <form className="mt-5" onSubmit={validateForm}>
          <div className="form-group">
            <label className=" forms-label userinfo-labels">
              Current Password*
            </label>
            <input
              type="password"
              placeholder="******"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className=" forms-label userinfo-labels">
              New Password*
            </label>
            <input
              type="password"
              placeholder="******"
              className="form-control"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className=" forms-label userinfo-labels">
              Confirm New Password*
            </label>
            <input
              type="password"
              placeholder="******"
              className="form-control"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </div>

          <div className="form-group mb-5 second-msg">
            <h6 className="font-weight-bold userinfo-labels">
              You will receive a message confirming your request.
            </h6>
          </div>

          <button
            type="submit"
            className="common-btn text-white second-btn"
            style={{ marginTop: "45px" }}
          >
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

// export default SecondForm;

SecondForm.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(SecondForm);
