import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { RiUserShared2Fill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import MetaMaskWallet from "../../pages/user data/collection/MetaMaskWallet";
import profileIcon from "../../assets/profileicon.jpg";
import { FiLogOut } from "react-icons/fi";
import "../../styles/home style/Home.css";

export default function UserPopup() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [style, setStyle] = useState({ display: "none" });
  const [user, setUser] = useState("");
  const [items, setItems] = useState("");

  const [flow, setFlow] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  var adds = sessionStorage.getItem("wallet address");

  function handleBlockchain() {
    var add = sessionStorage.getItem("wallet address");
    var slicedArray = add.slice(6, 38);
    var newstring = add.split(slicedArray).join("...");
    setItems(newstring);
    window.location.href = "/user-info";
  }

  const navigate = useNavigate();
  const logoutUser = () => {
    sessionStorage.clear();
    localStorage.removeItem("niftMintUser");
    localStorage.removeItem("niftMintToken");
    navigate("/login");
  };

  const goToProfile = () => {
    navigate("/account-setting");
  };
  // const { username } = JSON.parse(sessionStorage.getItem("niftMintUser"));

  var wallet = sessionStorage.getItem("wallet address");
  if (wallet) {
    var slicedArray = wallet.slice(6, 38);
    wallet = wallet.split(slicedArray).join("...");
  }

  return (
    <div>
      {/* <img
        src={profileIcon}
        className="profile-icon"
        height={40}
        width={40}
        style={{ cursor: "pointer", borderRadius: "50%" }}
      /> */}
      <div className="dropdown">
        <img
          src={profileIcon}
          className="profile-icon"
          height={40}
          width={40}
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />{" "}
        <div className="dropdown-content">
          <a onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            Home
          </a>
          <a onClick={goToProfile} style={{ cursor: "pointer" }}>
            Profile
          </a>
          <a onClick={() => navigate("/ordinal")} style={{ cursor: "pointer" }}>
            Bitcoin Ordinal
          </a>
          <a onClick={() => logoutUser()} style={{ cursor: "pointer" }}>
            Logout
          </a>
        </div>
      </div>
      {/* <FiLogOut
        size={25}
        className="font-weight-bold cursor-pointer common-color logout-icon"
        title="Logout"
        onClick={logoutUser}
      /> */}
    </div>
  );
}
