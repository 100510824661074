import { useState, createContext, useEffect } from "react";

import { ethers, BigNumber } from "ethers";
import ABI from "../utils/ABI.json";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [status, setStatus] = useState("");

  const transferNFT = async (
    contractAddress,
    fromAddress,
    toAddress,
    tokenId
  ) => {
    const provider = new ethers.providers.InfuraProvider(
      "matic",
      process.env.REACT_APP_INFURA_ID
    );
    // const provider = new ethers.providers.AlchemyProvider("matic", process.env.INFURA_ID)
    const wallet = new ethers.Wallet(process.env.REACT_APP_NIFT_KEY, provider);
    const contract = new ethers.Contract(contractAddress, ABI, wallet);
    const gasPrice = await provider.getGasPrice();
    const gasLimit = 300000;
    try {
      const tx = await contract.transferFrom(fromAddress, toAddress, tokenId, {
        gasLimit: gasLimit,
        gasPrice: gasPrice,
      });
      const receipt = await tx.wait();
      return {
        success: true,
        status: `✅ Check out your transaction on Polygon Network: https://polygonscan.com/tx/${receipt.transactionHash}`,
        data: receipt,
      };
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  };

  const mintNFT = async (brandWallet, uri, contractAddress, nft) => {
    const provider = new ethers.providers.InfuraProvider(
      "matic",
      process.env.REACT_APP_INFURA_ID
    );
    // const provider = new ethers.providers.AlchemyProvider("matic", process.env.INFURA_ID)
    const wallet = new ethers.Wallet(process.env.REACT_APP_NIFT_KEY, provider);
    const contract = new ethers.Contract(contractAddress, ABI, wallet);
    const gasPrice = await provider.getGasPrice();
    const gasLimit = 300000;
    const totalSupply = await contract.totalSupply();

    try {
      const tx = await contract.mint(brandWallet, uri, {
        gasLimit: gasLimit,
        gasPrice: gasPrice,
      });

      const receipt = await tx.wait();
      const logs = receipt.logs;
      const tokenIdFinder = logs[0].topics[3];
      const tokenId = BigNumber.from(tokenIdFinder).toNumber();
      const date = new Date();
      const timestamp = date.toISOString();
      const nftName = nft.name;
      const symbol = nftName.substring(0, 3);
      const txResponse = {
        timestamp: timestamp,
        action: "mint",
        tx: {
          timestamp: timestamp,
          method: "mint(address,string)",
          target: contractAddress,
          template: "NIFT721nBrandNFTv2022r0",
          hash: receipt.transactionHash,
        },
        collectibleData: {
          ref: `PID:${nft.contractAddress}:${tokenId}`,
          metaData: {
            uri: uri,
          },
          holder: brandWallet,
          custody: true,
        },
        collection: {
          ref: nft.contractAddress,
          operator: "0x82CA35e0a2a93CE3FF988C499C436108Ac440813",
          brand: brandWallet,
          totalSupply: totalSupply.toString(),
          metaData: {
            name: nftName,
            symbol: symbol,
          },
        },
      };

      return {
        success: true,
        status: `✅ Check out your transaction on Polygon Network: https://polygonscan.com/tx/${receipt.transactionHash}`,
        receipt: txResponse,
      };
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }
  };

  return (
    <UserContext.Provider value={{ status, setStatus, mintNFT, transferNFT }}>
      {children}
    </UserContext.Provider>
  );
};
