import React, { useEffect, useState } from "react";
import "../../styles/users style/CompleteAccount.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  API_BASE_URL,
  API_ROUTES,
} from "../../constants/ApiBaseUrl";
import { toast } from "react-toastify";
import Axios from "axios";
import HomeHeader from "../../components/headers/HomeHeader";
import { configOption } from "../../utils/HttpHeaders";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  setUserLocally,
  setCurrentUser,
  setError,
  setToken,
} from "../../state/actions/auth";

const CompleteAccountForm = (props) => {
  const navigate = useNavigate();
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [role, setRole] = useState("");
  const [organization, setOrganization] = useState("");
  const [interestArea, setInterestArea] = useState("");

  useEffect(() => {}, []);

  const validateForm = (e) => {
    e.preventDefault();
    var { email } = JSON.parse(localStorage.niftMintUser);

    if (!first_name || !last_name || !email) {
      toast.error("Please fill all the fields.");
      return false;
    } else {
      handleSubmit();
    }
  };
  // var { email } = JSON.parse(localStorage.niftMintUser);

  const handleSubmit = async () => {
    const { _id } = JSON.parse(localStorage.niftMintUser);
    try {
      const res = await Axios.patch(
        `${API_BASE_URL}${API_ROUTES.USER.COMPLETE_ACCOUNT}/${_id}`,
        {
          firstName: first_name,
          lastName: last_name,
        },
        configOption()
      );
      setFirst_name("");
      setLast_name("");
      setInterestArea("");
      if (res.status === 200) {
        toast.success("Details updated successfully.");
        window.location.href = "/";
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <div>
      <div className="container d-flex justify-content-center align-items-center mt-3 mb-3 complete-container">
        <div className="row justify-content-center ">
          <div className="col-11 col-sm-10  col-md-10 col-lg-10 complete-account-section p-5 complete-account-row">
            <div className="header">
              <h2
                className="title font-weight-bold text-dark"
                style={{ color: "#B2BEB5" }}
              >
                Complete your Account
              </h2>
              <p className="text">
                Answer the questions below to complete your account and be able
                to acquire your first Digital Collectible with Christology
                Statement.
              </p>
            </div>
            <div className="form">
              <form onSubmit={validateForm}>
                <div className="form-group">
                  <div className="form-group">
                    <label
                      for="pwd"
                      className="forms-label "
                      style={{ color: "#B2BEB5" }}
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      id="pwd"
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      for="pwd"
                      className="forms-label"
                      style={{ color: "#B2BEB5" }}
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      id="pwd"
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label for="email" className="forms-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <label
                      className="forms-label"
                      style={{ color: "#B2BEB5" }}
                    >
                      Organization
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Organization"
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <label
                      className="forms-label"
                      style={{ color: "#B2BEB5" }}
                    >
                      Role
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <label
                      className="forms-label"
                      style={{ color: "#B2BEB5" }}
                    >
                      Area of Interest
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Area of Interest"
                      value={interestArea}
                      onChange={(e) => setInterestArea(e.target.value)}
                    />
                  </div> */}
                  {/* <label className="forms-label ">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="@username"
                    defaultValue={username}
                    onChange={(e) => setUserNames(e.target.value)}
                  /> */}
                </div>

                <div className="d-flex justify-content-between mt-4 divide-row">
                  <div className=" complete-account-section">
                    <Link to="/">
                      <button
                        className="complete-account-btn  text-white"
                        style={{
                          background: "#999999",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Complete Later
                      </button>
                    </Link>
                  </div>
                  <div className=" complete-account-section">
                    <button
                      type="submit"
                      className="complete-account-btn text-white "
                      style={{ borderRadius: "5px", cursor: "pointer" }}
                    >
                      {" "}
                      Continue
                    </button>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-5 mb-4">
                  <span className="slidez"></span>
                  <span className="slidey"></span>
                  <span className="slides"></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default CompleteAccountForm;

CompleteAccountForm.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(CompleteAccountForm);
