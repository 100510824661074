import React from "react";
import "../../../styles/users style/OrdinalViewer.css";

function OrdinalViewer() {
  const details = {
    inscriptionNumber: "#3524119",
    inscriptionId:
      "d036fa927404a1f047432fe98c3b8d064a266d349fda559987b5525e16eb422ei0",

    address: "bc1pt3h5444yfmxk6gfryqtc35f58lgxv5jp44kukz79vnsvgyc9n5aqp6sqgt",
    outputValue: "8968",
    contentLink: "1ink",
    contentLength: "812646",
    contentType: "application/pdf",
    timestamp: "1 days ago",
    genesisHeight: "812646",
    genesisTransaction:
      "d036fa927404a1f047432fe98c3b8d064a266d349fda559987b5525e16eb422e",
    viewLink:
      "https://ordinalscan.net/inscription/d036fa927404a1f047432fe98c3b8d064a266d349fda559987b5525e16eb422ei0",
  };

  return (
    <div className="containerOrdinal">
      <div className="pdf-viewer">
        <iframe src="assets/ordinal.pdf" title="PDF viewer"></iframe>
      </div>

      <div className="ordinal-details">
        <div className="label">Inscription Number:</div>{" "}
        <a
          className="value"
          href="https://ordinals.com/inscription/d036fa927404a1f047432fe98c3b8d064a266d349fda559987b5525e16eb422ei0"
          target="_blank"
        >
          {details.inscriptionNumber}
        </a>
        <div className="label">Inscription Id:</div>{" "}
        <div className="value">{details.inscriptionId}</div>
        <div className="label">Address:</div>{" "}
        <div className="value">{details.address}</div>
        <div className="label">Output Value:</div>{" "}
        <div className="value">{details.outputValue}</div>
        <div className="label">Content:</div>{" "}
        <a href="assets/ordinal.pdf" target="_blank" className="value link">
          Link
        </a>
        <div className="label">Content Length:</div>{" "}
        <div className="value">{details.contentLength}</div>
        <div className="label">Content Type:</div>{" "}
        <div className="value">{details.contentType}</div>
        <div className="label">Timestamp:</div>{" "}
        <div className="value">{details.timestamp}</div>
        <div className="label">Genesis Height:</div>{" "}
        <div className="value">{details.genesisHeight}</div>
        <div className="label">Genesis Transaction:</div>{" "}
        <a
          className="value"
          href="https://mempool.space/tx/d036fa927404a1f047432fe98c3b8d064a266d349fda559987b5525e16eb422e"
          target="_blank"
        >
          {details.genesisTransaction}
        </a>
        <div className="label">View:</div>{" "}
        <a href={details.viewLink} target="_blank" className="value link">
          ordinalsca.net
        </a>
      </div>
    </div>
  );
}

export default OrdinalViewer;
