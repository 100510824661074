import React from "react";
import HomeHeader from "../headers/HomeHeader";
import LoginButtonHeader from "../headers/LoginButtonHeader";

const PrivacyPolicy = () => {
  const auth = JSON.parse(sessionStorage.getItem("niftMintUser"));
  return (
    <div>
      {auth ? <HomeHeader /> : <LoginButtonHeader />}
      <div className="container my-4">
        <div className="row">
          <div className="col-md-12">
            <div>
              <div>
                <h2
                  className="font-weight-bold common-color pt-4 text-center"
                  style={{ color: "#B2BEB5" }}
                >
                  Privacy Policy
                </h2>
              </div>
              <div>
                <p className="pt-3 ">
                  Please refer to our Privacy Policy for information about how
                  we collect, use, and share personal data about you. By
                  submitting personal data through our Service, you agree to the
                  terms of our Privacy Policy and you expressly consent to the
                  collection, use, and disclosure of your personal data in
                  accordance with the Privacy Policy.
                </p>
              </div>
            </div>

            <div>
              <div>
                <h3
                  className="font-weight-bold  common-footer-heading common-color pt-4"
                  style={{ color: "#B2BEB5" }}
                >
                  I. Modifications to the Service
                </h3>
              </div>
              <div>
                <p className="pt-3 ">
                  We reserve the right in our sole discretion to modify,
                  suspend, or discontinue, temporarily or permanently, the
                  Service (or any features or parts thereof) at any time and
                  without liability as a result.
                </p>
              </div>
            </div>

            <div>
              <div>
                <h3
                  className="font-weight-bold  common-footer-heading common-color pt-4"
                  style={{ color: "#B2BEB5" }}
                >
                  II. Survival
                </h3>
              </div>
              <div>
                <p className="pt-3 ">
                  All sections which by their nature should survive the
                  termination of these Terms shall continue in full force and
                  effect subsequent to and notwithstanding any termination of
                  these Terms by Christology Statement or you. Termination will
                  not limit any of Christology Statement’s rights.
                </p>
              </div>
            </div>

            <div>
              <div>
                <h3
                  className="font-weight-bold  common-footer-heading common-color pt-4"
                  style={{ color: "#B2BEB5" }}
                >
                  III. Miscellaneous
                </h3>
              </div>
              <div>
                <p className="pt-3 ">
                  These Terms constitute the entire agreement between you and
                  Christology Statement relating to your access to and use of
                  the Service. These Terms, and any rights and licenses granted
                  hereunder, may not be transferred or assigned by you without
                  the prior written consent of Christology Statement, and
                  Christology Statement’s failure to assert any right or
                  provision under these Terms shall not constitute a waiver of
                  such right or provision. No waiver by either party of any
                  breach or default hereunder shall be deemed to be a waiver of
                  any preceding or subsequent breach or default. The section
                  headings used herein are for reference only and shall not be
                  read to have any legal effect.
                </p>
                <p className="pt-3 ">
                  The Service is operated by us in the United States. Those who
                  choose to access the Service from locations outside the United
                  States do so at their own initiative and are responsible for
                  compliance with applicable local laws. You and Christology
                  Statement agree that the United Nations Convention on
                  Contracts for the International Sale of Goods will not apply
                  to the interpretation or construction of these Terms.
                </p>

                <p className="pt-3 ">
                  The Service is operated by us in the United States. Those who
                  choose to access the Service from locations outside the United
                  States do so at their own initiative and are responsible for
                  compliance with applicable local laws. You and Christology
                  Statement agree that the United Nations Convention on
                  Contracts for the International Sale of Goods will not apply
                  to the interpretation or construction of these Terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
