import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import TagsInput from "react-tagsinput";
import {
  API_BASE_URL,
  API_ROUTES,
  BRAND_WALLET,
} from "../../constants/ApiBaseUrl";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../components/logo/logo";
import { configOption } from "../../utils/HttpHeaders";
import axios from "axios";
import { connect } from "react-redux";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            style={{
              backgroundColor: "#101010",
              color: "white",
              borderRadius: "25px",
              padding: "4px 4px",
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const InviteModal = (props) => {
  const [tags, setTags] = React.useState([]);
  const [minEmailAddressesError, setMinEmailAddressesError] =
    React.useState(false);

  const inviteByMail = async (e) => {
    e.preventDefault();
    console.log("HERE 2")
    console.log(tags)
    if (tags.length > 0) {
      setMinEmailAddressesError(false);
      const email = props.auth.user.email;
      const endpoint = API_ROUTES.NFT.NFT_TWO;
      const data = {
        emails: tags,
        // nft: props.selectedNft,
        // email: email,
      };
      console.log("HERE 3")
      const res = await axios.post(
        `${API_BASE_URL}/test`,
        data,
        configOption()
      );
      if (res.status === 200) {
        props.handleCloseInvite();
      }
    } else {
      // toast.error("Please enter email address");
      setMinEmailAddressesError(true);
    }
  };

  const handleTagsChange = (tags) => {
    setTags(tags);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <div>
      {/* Invite Dialog box */}
      <BootstrapDialog
        onClose={props.handleCloseInvite}
        aria-labelledby="customized-dialog-title"
        open={props.openInvite}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleCloseInvite}
        >
          <Logo />
        </BootstrapDialogTitle>
        {props.openInvite === true && (
          <>
            <DialogContent dividers style={{ width: "600px" }}>
              <h4 className="font-weight-bold buy-title">Invite</h4>
              <Typography gutterBottom style={{ fontSize: "14px" }}>
                Please invite others by entering three email addresses to claim
                2nd NFT
              </Typography>
              {minEmailAddressesError ? (
                <Typography
                  gutterBottom
                  style={{ fontSize: "14px", color: "red" }}
                >
                  Please enter atleast 1 email address.
                </Typography>
              ) : (
                <div></div>
              )}

              <div className="form mt-3">
                <form onSubmit={inviteByMail}>
                  <div className="form-group">
                    <div className="form-group">
                      <label className="forms-labelss">E-Mail</label>
                      <TagsInput
                        inputProps={{
                          placeholder: "Email ",
                        }}
                        value={tags}
                        onChange={handleTagsChange}
                        onlyUnique={true}
                        addOnBlur={true}
                        validationRegex={emailRegex}
                      />
                    </div>
                  </div>

                  <div className="text-white text-right mb-3">
                    <button type="submit" className="dialog-btn ">
                      Invite
                    </button>
                  </div>
                </form>
              </div>
            </DialogContent>
          </>
        )}
      </BootstrapDialog>
    </div>
  );
};

// export default InviteModal;

InviteModal.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(InviteModal);
