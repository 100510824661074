import React from "react";
import { Audio, Oval, RotatingLines } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Spinner = ({ loading }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center "
      // style={{ background: "lightGray" }}
    >
      {loading ? (
        <Oval
          height="100vh"
          width="100"
          // color="#383A3C"
          // backgtoundColor="#383A3C"
          ariaLabel="loading"
          strokeWidth={2}
        />
      ) : (
        // <RotatingLines height="100vh" width="100" strokeColor="#383A3C" />
        //  strokeWidth={5}
        ""
      )}
    </div>
  );
};

export default Spinner;
