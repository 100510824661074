import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import light from "../../assets/light.png";
import dark from "../../assets/Christology.png";
const Logo = () => {
  let logo;

  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    // dark mode
    logo = (
      <img
        src={dark}
        alt=""
        className="mobile-logo"
        style={{ cursor: "pointer", objectFit: "contain", marginLeft: -64 }}
      />
      //   </Link>
    );
  } else {
    logo = (
      <img
        src={dark}
        alt=""
        className="mobile-logo"
        style={{ cursor: "pointer", objectFit: "contain", marginLeft: -64 }}
      />
      //   </Link>
    );
  }
  const isDarkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  return <>{logo}</>;
};

export default Logo;
