import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = (getDefaultMiddleware) => {
  if (process.env.NODE_ENV === "production") {
    return getDefaultMiddleware().concat(thunk);
  } else {
    return getDefaultMiddleware().concat(thunk);
  }
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
