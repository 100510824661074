export const ActionType = {
  // auth
  SET_CURRENT_USER: "SET_CURRENT_USER",
  CLEAR_CURRENT_USER: "CLEAR_CURRENT_USER",
  LOGOUT_USER: "LOGOUT_USER",
  SIGNING: "SIGNING",

  // error
  SET_ERROR: "SET_ERROR",
  SET_UNAUTHENTICATED: "SET_UNAUTHENTICATED",
};
