import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import LoginButtonHeader from "../../components/headers/LoginButtonHeader";
import "../../styles/auth style/auth.css";
import {
    API_BASE_URL,
    API_ROUTES,
} from "../../constants/ApiBaseUrl";
import {CAPTCHA_SITE_KEY} from "../../config/config";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import Axios from "axios";
import Spinner from "../../components/spinner/Spinner";
import {
    setUserLocally,
    setCurrentUser,
    setError,
    setToken,
} from "../../state/actions/auth";

const Register = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirm_Password] = useState("");
    const [role, setRole] = useState(["user"]);
    const [isVerifiedCaptcha, setIsVerifiedCaptcha] = useState(false);
    const [nftNames, setNftNames] = useState([]);
    const [selectedNft, setSelectedNft] = useState("");
    const [loading, setLoading] = useState(false);
    const [referral, setReferral] = useState("");

    const navigate = useNavigate();

    const validateForm = (e) => {
        e.preventDefault();

        if (!email || !password || !confirm_password) {
            toast.error("Please fill all the fields.");
            return false;
        }
            // else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            //   toast.error("invalid email");
            //   return false;
        // }
        else if (password.length < 8) {
            toast.error("Password length must be greater than 8 characters.");
            return false;
        } else if (password !== confirm_password) {
            toast.error("Passwords do not match.");
            return false;
        } else {
            handleSubmit();
        }
    };

    const handleSelectChange = (e) => {
        setSelectedNft(e.target.value);
    };
    const handleSubmit = async () => {
        try {
            setLoading(true);
            const res = await Axios.post(
                `${API_BASE_URL}${API_ROUTES.AUTH_USER.SIGNUP}`,
                {
                    email: email,
                    password: password,
                    referral: referral
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (res.data.statusCode === 201) {
                toast.success("Registered successfully. Please check your e-mail.");
                //const user = setUserLocally(res.data.data);
                //props.dispatch(setCurrentUser(user));
                //localStorage.setItem("niftMintToken", user.jwtToken);
                // props.dispatch(setToken(user));
                props.dispatch(setError({}));
                setEmail("");
                setPassword("");
                setConfirm_Password("");
                setReferral("")
                setTimeout(function () {
                    setLoading(false);
                }, 2000);
                navigate("/verify-otp");
            }
        } catch (error) {
            setLoading(false);
            console.log("error", error);
            toast.error(error.response.data.message);
        }
    };

    const handleCaptcha = (value) => {
        if (value) {
            setIsVerifiedCaptcha(true);
        }
    };
    useEffect(async () => {
    }, []);

    return (
        <>
            <LoginButtonHeader/>

            <div className="container  register-container ">
                <div className=" ">
                    <Spinner loading={loading}/>
                </div>
                <div className={` ${loading === true ? "notready" : "ready"}`}>
                    <div className="row  mt-3 mb-3 common-row d-flex justify-content-center">
                        <div className="col-12 col-sm-12 col-md-9 col-lg-6">
                            <img
                                src="/assets/images/commongif.gif"
                                className=" register-img gif"
                                alt=""
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-9 col-lg-6 register-section-second">
                            <div className="header">
                                <h2 className="title" style={{color: "#B2BEB5"}}>
                                    Claim your Christology Statement Digital Collectible
                                </h2>
                                <p className="text">
                                    Enter your information to claim your Digital Collectible
                                </p>
                            </div>
                            <div className="form">
                                <form onSubmit={validateForm}>
                                    <div className="form-group">
                                        <label
                                            className="forms-labels"
                                            for="email"
                                            style={{color: "#B2BEB5"}}
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="your@email.com"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            for="pwd"
                                            className="forms-labels"
                                            style={{color: "#B2BEB5"}}
                                        >
                                            Password
                                        </label>
                                        <input
                                            type="Password"
                                            className="form-control"
                                            placeholder="Password"
                                            id="pwd"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            for="pwd"
                                            className="forms-labels"
                                            style={{color: "#B2BEB5"}}
                                        >
                                            Confirm Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            id="cpwd"
                                            value={confirm_password}
                                            onChange={(e) => setConfirm_Password(e.target.value)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor="referral"
                                            className="forms-labels"
                                            style={{color: "#B2BEB5"}}
                                        >
                                            Referral
                                        </label>
                                        <input
                                            type="Text"
                                            className="form-control"
                                            placeholder="Referral code or referrer's email address"
                                            id="referral"
                                            value={referral}
                                            onChange={(e) => setReferral(e.target.value)}
                                        />
                                    </div>

                                    <div className="form-check register-text">
                                        <label className="form-check-label" for="exampleCheck1">
                                            By proceeding, I agree to Christology Statement’s
                                            <span className="">
                                                {" "}
                                                <a
                                                    href="/terms-condition"
                                                    target="_blank"
                                                    className="terms-text "
                                                >
                                                Terms & Conditions
                                                </a>
                                                </span>{" "}
                                            and acknowledge that I have read the
                                            <span className="">
                                                {" "}
                                                <a
                                                    href="/privacy-policy"
                                                    target="_blank"
                                                    className="terms-text "
                                                >
                                                Privacy Policy.
                                                </a>
                                            </span>
                                        </label>
                                    </div>

                                    <div className="mt-3 d-flex justify-content-end">
                                        <ReCAPTCHA
                                            sitekey={CAPTCHA_SITE_KEY}
                                            onChange={handleCaptcha}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-between mt-3 divide-row">
                                        <div
                                            className="w-90 d-flex   align-items-center"
                                            // style={{ flex: "1" }}
                                        >
                                            <p>
                                                Already have an account?{" "}
                                                <Link to="/login">
                                                    <span className="terms-text"> Login</span>
                                                </Link>
                                            </p>
                                        </div>
                                        <div className="w-10 register-btn-section">
                                            <button
                                                type="submit"
                                                // className="btn btn-primary  register-btn"
                                                className={` ${
                                                    isVerifiedCaptcha === true
                                                        ? "btn btn-primary  register-btn"
                                                        : "btn btn-primary  register-btndisable"
                                                }`}
                                                disabled={!isVerifiedCaptcha}
                                            >
                                                Register
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

// export default Register;

Register.propTypes = {
    auth: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    error: state.error.error,
    auth: state.auth,
});

export default connect(mapStateToProps)(Register);
