import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginButtonHeader from "../../components/headers/LoginButtonHeader";
import "../../styles/auth style/auth.css";
import {
  API_BASE_URL,
  API_ROUTES,
  APP_ORG_NAME,
} from "../../constants/ApiBaseUrl";
import { configOption } from "../../utils/HttpHeaders";
import { toast } from "react-toastify";
import Axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  setUserLocally,
  setCurrentUser,
  setError,
  setToken,
} from "../../state/actions/auth";
const VerifyOtp = (props) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const validateForm = (e) => {
    e.preventDefault();

    if (!otp) {
      toast.error("Please fill all the fields.");
      return false;
    } else {
      handleSubmit();
    }
  };

  const { auth } = props;
  const handleSubmit = async () => {
    try {
      const res = await Axios.get(
        `${API_BASE_URL}${API_ROUTES.AUTH_USER.VERIFY_OTP}/${auth.user._id}/org/${process.env.REACT_APP_ORG_ID}/otp/${otp}`,
        configOption()
      );
      console.log("resss", res);
      if (res.data.statusCode === 200) {
        toast.success(res.data.message);
        const user = setUserLocally(res.data.data);
        setToken(user);
        props.dispatch(setCurrentUser(user));
        props.dispatch(setError({}));
        navigate("/complete-account");
      }
      // if (res.status === 200) {
      //   toast.success(res.data.data.message);
      //   const user = setUserLocally(res.data.data);
      //   setToken(user);
      //   props.dispatch(setCurrentUser(user));
      //   props.dispatch(setError({}));
      //   navigate("/complete-account");
      // }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const resendOtp = async () => {
    try {
      const res = await Axios.post(
        `${API_BASE_URL}${API_ROUTES.AUTH_USER.RESEND_OTP}`,
        {
          id: auth.user._id,
        },
        configOption()
      );

      if (res.status === 200) {
        toast.success("OTP sent.");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <LoginButtonHeader />

      <div className="container verifyemail-container ">
        <div className="row mt-3 mb-3 common-row ">
          <div className="col-12 col-md-6 col-lg-6">
            <img
              src="/assets/images/commongif.gif"
              className="w-100 gif"
              alt=""
            />
          </div>

          <div className="col-12 col-md-6 col-lg-6 verifyemail-section-second ">
            <div className="header">
              <h2 className="title" style={{ color: "#B2BEB5" }}>
                Please verify your email
              </h2>

              <p className="text">
                Please check your email for a one-time-password and add below.
                Didn’t receive it?{" "}
                <span
                  className="terms-text"
                  style={{ cursor: "pointer", fontSize: "17px" }}
                  onClick={resendOtp}
                >
                  Resend
                </span>
              </p>
            </div>
            <div className="form">
              <form onSubmit={validateForm}>
                <div className="form-group">
                  <label
                    for="pwd"
                    className="forms-labels"
                    style={{ color: "#B2BEB5" }}
                  >
                    One Time Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="One Time Password"
                    id="pwd"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>

                <div className="d-flex justify-content-between align-items-cente mt-3 divide-row">
                  <div className="w-90 d-flex align-items-center">
                    <p>
                      Have an account?
                      <Link to="/login">
                        <span
                          className="terms-text"
                          style={{ fontSize: "18px" }}
                        >
                          {" "}
                          Login
                        </span>
                      </Link>
                    </p>
                  </div>
                  <div className=" verifybtn-section">
                    <button type="submit" className="btn btn-primary login-btn">
                      Verify OTP
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

VerifyOtp.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(VerifyOtp);
