import React, { useContext, useEffect, useState } from "react";
import "../../styles/user data/userdata.css";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            style={{
              backgroundColor: "#101010",
              color: "white",
              borderRadius: "25px",
              padding: "4px 4px",
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const MintSuccess = (props) => {
  return (
    <BootstrapDialog
      onClose={props.handleCloseSuccess}
      aria-labelledby="customized-dialog-title"
      open={props.openSuccess}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={props.handleCloseSuccess}
      >
        {/* <Logo /> */}
      </BootstrapDialogTitle>
      {props.openSuccess === true && (
        <>
          <DialogContent dividers style={{ width: "600px" }}>
            <h3 className="transfer-heading">
              You have successfully Minted the NFT
            </h3>
          </DialogContent>
        </>
      )}
    </BootstrapDialog>
  );
};

export default MintSuccess;
