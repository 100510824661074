import { ActionType } from "../actionTypes/index";

const initialState = {
  error: {},
};

function errorReducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.SET_ERROR:
      return {
        error: action.payload,
      };
    case ActionType.SIGNING:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default errorReducer;
