class Validation {
  static isEmpty(value) {
    return (
      value === undefined ||
      value === null ||
      (typeof value === "object" && Object.keys(value).lenth === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  }
}

const validation = Validation;
export default validation;
