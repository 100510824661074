import React, { useContext, useEffect, useState } from "react";
import { LockedNFT, StandardNFTCard } from "../../components/Cards/NftCards";
import {
  API_BASE_URL,
  API_ROUTES,
  BRAND_WALLET,
} from "../../constants/ApiBaseUrl";
import { configOption } from "../../utils/HttpHeaders";
import Axios from "axios";
import "../../styles/user data/userdata.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TagsInput from "react-tagsinput";
import MintSuccess from "../../components/modals/MintSucess";
import { connect } from "react-redux";
import Spinner from "../../components/spinner/Spinner";
import "react-tagsinput/react-tagsinput.css";
import Logo from "../../components/logo/logo";
import { UserContext } from "../../context/context";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon
            style={{
              backgroundColor: "#101010",
              color: "white",
              borderRadius: "25px",
              padding: "4px 4px",
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Home = (props) => {
  const { mintNFT } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [invitePopup, setInvitePopup] = useState(false);
  const [allNfts, setAllNfts] = useState([]);
  const [openInvite, setOpenInvite] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [tags, setTags] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const [minEmailAddresses, setMinEmailAddresses] = useState(1);
  const [selectedNft, setSelectedNft] = useState({});
  const [minEmailAddressesError, setMinEmailAddressesError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  // const [email, setEmail] = useState("");
  const email = props.auth.user.email;
  const handleTagsChange = (tags) => {
    setTags(tags);
  };

  const inviteByMail = async (e) => {
    e.preventDefault();
    try {
      if (tags.length < minEmailAddresses + 1) {
        setMinEmailAddressesError(true);
      } else {
        setMinEmailAddressesError(false);
        handleCloseInvite();
        await claimNft(selectedNft);
      }
    } catch (error) {}
  };
  const handleCloseSuccessModal = () => {
    setOpenSuccess(false);
  };
  const handleCloseInvite = () => {
    setOpenInvite(false);
    setInvitePopup(false);
  };
  const handleOpenInvite = (nft) => {
    setOpenInvite(true);
    setInvitePopup(true);
    setMinEmailAddresses(nft.display_order);
    setSelectedNft(nft);
  };

  async function claimNft(nft) {
    try {
      setIsLoading(true);
      console.log(`Claim NFT ${nft.display_order}, ${nft.nft_id}`)

      const res = await Axios.post(
        `${API_BASE_URL}${API_ROUTES.NFT.NFT}/${nft.nft_id}`,
        null,
          configOption()
      );
      console.log(res);

      if (res.status === 201) {
        /*
        // Minting moved to backend.
        const { data } = res.data.data;
        const contractAddress = nft.contractAddress;
        const cAddress = contractAddress.replace(
          /^CID:NIFT721nBrandNFTv2022r0:137:/,
          ""
        );
        const txResponse = await mintNFT(
          BRAND_WALLET,
          nft.metaData.uri,
          cAddress,
          nft
        );
        console.log("txResponse", txResponse);
        console.log("txResponse.receipt", txResponse.receipt);
        if (txResponse.success) {
          const record = await Axios.patch(
            `${API_BASE_URL}${API_ROUTES.NFT.NFT_MINTED}/${nft.id}`,
            txResponse.receipt,
            configOption()
          );

          console.log("record", record);
        } else {
          toast.error("Minting Error please try again");
          setIsLoading(false);
        }
        */
        toast.success("Minting Completed");
        setIsLoading(false);
      } else {
        toast.error("Minting Error please try again");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  const getClaimedNft = async () => {
    try {
      setLoadingPage(true);

      const res = await Axios.get(
        `${API_BASE_URL}${API_ROUTES.NFT.NFT}`,
        configOption()
      );
      setLoadingPage(false);
      setAllNfts(res.data);
      const nft = res.data[2];

      setIsAvailable(nft.available);
    } catch (error) {
      setIsAvailable(false);
    }
  };

  useEffect(() => {
    getClaimedNft();
  }, [props.auth.user, isLoading]);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return (
    <div className="container">
      <div className=" ">
        <Spinner loading={loadingPage} />
      </div>
      <div className={` ${loadingPage === true ? "notready" : "ready"}`}>
        <div
          className="row"
          style={{
            marginInline: "15px",
          }}
        >
          {allNfts.map((nft, index) => (
            <div style={{ marginInline: "10px" }} key={index}>
              {nft.minted || index === 0 ? (
                <StandardNFTCard
                  claimLoading={allNfts}
                  nft={nft}
                  index={index}
                  claimNft={claimNft}
                  navigate={navigate}
                  isLoading={isLoading}
                  settingFunction={() => setSelectedIdx(index)}
                  isSelected={selectedIdx}
                  {...props}
                />
              ) : (
                <>
                  <LockedNFT
                    referrals={nft.display_order}
                    nft={nft}
                    index={index}
                    isLoading={isLoading}
                    claimNft={claimNft}
                    isAvailable={isAvailable}
                    navigate={navigate}
                    openInviteModal={handleOpenInvite}
                    settingFunction={() => setSelectedIdx(index)}
                    isSelected={selectedIdx}
                    {...props}
                  />
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      {/* Invite Dialog box */}
      <BootstrapDialog
        onClose={handleCloseInvite}
        aria-labelledby="customized-dialog-title"
        open={openInvite}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseInvite}
        >
          <Logo />
        </BootstrapDialogTitle>
        {invitePopup === true && (
          <>
            <DialogContent dividers style={{ width: "600px" }}>
              <h4 className="font-weight-bold buy-title">Invite</h4>
              <Typography gutterBottom style={{ fontSize: "14px" }}>
                Please invite others by entering three email addresses to claim
                2nd NFT{" "}
              </Typography>
              {minEmailAddressesError ? (
                <Typography
                  gutterBottom
                  style={{ fontSize: "14px", color: "red" }}
                >
                  Please enter atleast {minEmailAddresses + 1} email addresses.
                </Typography>
              ) : (
                <div></div>
              )}

              <div className="form mt-3">
                <form onSubmit={inviteByMail}>
                  <div className="form-group">
                    <div className="form-group">
                      <label className="forms-labelss">E-Mail</label>
                      <TagsInput
                        inputProps={{
                          placeholder: "Email ",
                        }}
                        value={tags}
                        onChange={handleTagsChange}
                        onlyUnique={true}
                        addOnBlur={true}
                        validationRegex={emailRegex}
                      />
                    </div>
                  </div>

                  <div className="text-white text-right mb-3">
                    <button type="submit" className="dialog-btn ">
                      Invite
                    </button>
                  </div>
                </form>
              </div>
            </DialogContent>
          </>
        )}
      </BootstrapDialog>

      {/* Invite dialog box end
      <MintSuccess
        openSuccess={openSuccess}
        handleCloseSuccess={handleCloseSuccessModal}
      /> */}
    </div>
  );
};

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
