export const APP_ORG_NAME =
  process.env.REACT_APP_ORG_NAME || "Christology";
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL_V3 || "http://localhost:3000";
export const BRAND_WALLET = process.env.REACT_APP_BRAND_WALLET;
export const BRAND_WALLET_TRANSFER =
  process.env.REACT_APP_BRAND_WALLET_TRANSFER;


export const API_ROUTES = {
  AUTH_USER: {
    // DONE:
    LOGIN: "/auth/login ",
    SIGNUP: "/auth/register",
    VERIFY_OTP: "/auth/verify",

    // TODO:
    VERIFY_ACCOUNT: "/auth/verify-account",
    RESEND_OTP: "auth/generateOtp",
    GENERATE_OTP: "/auth/generate-otp",
    FORGET_PASSWORD_STEP1: "auth/OtpSentViaEmail",
    FORGET_PASSWORD_STEP2: "auth/fpotpVerify",
    FORGET_PASSWORD_STEP3: "/auth/reset-password",
    PASSWORD_RESET: "/auth/reset-password",
    ORG_NFTS: `/event/org/${APP_ORG_NAME}}`,
  },

  NFT: {
    NFT: "/nft",

    TRANSFER_NFT: "/nft/transferNFT",
    TRANSFER_NFTV2: "/nft/transfer",
    TRANSFER_CONFIRMED: "/nfts/transfer-confirm",
    GET_NFT: "/attendee/nft/detail",
    GET_NFTV2: "/nfts/single",
    NFT_TRANSFER_STATUS: "nft/transfer",
    NFT_MINT: "/attendee/mint",
    NFT_NFTV2: "/nft/mint",
    NFT_MINTED: "/nfts/minted",
    GET_ALL_NFT: "/attendee/nfts",
    CLAIM_NFT: "/nft/claim",
    CLAIMED_NFT: "/nft",  // "/nfts",

    NFT_ONE: "/nft",  // "/nfts/uri/first-nft",
    NFT_TWO: "/nft",  // "/nfts/uri/second-nft",
    NFT_THREE: "/nft",  // "/nfts/uri/third-nft",
    NFT_THIRD_CHECK: "/nfts/third-nft/check",
  },
  USER: {
    COMPLETE_ACCOUNT: "/user",
    COMPLETE_BIO: "user/setBio",
    USER_COLLECTION: "/nft/all/search",
    ACCOUNT_SETTING_FIRST_FORM: "user/changeAccountSettings",
    ACCOUNT_SETTING_SECOND_FORM: "/auth/reset-password/",
    ACCOUNT_SETTING_THIRD_FORM: "user/setMobileNumber",
    INVITE_BY_MAIL: "/nft/invite/users ",
  },
};
