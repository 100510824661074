import { Navigate } from "react-router-dom";
import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";

export default function ProtectedRoutes({ children }) {
  const user = localStorage.niftMintUser;
  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return <>{children}</>;
  }
  //   // const auth = JSON.parse(sessionStorage.getItem("niftMintUser"));
  //   const authenticatedUser = JSON.parse(user);
  //   return authenticatedUser ? <>{children}</> : <Navigate to="/login" />;
  // }
}
